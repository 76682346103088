@import "../assets/icons/all.min.css";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
body,
#root,
html {
  font-family: "Charlie Display" !important;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
main::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* REACT DATES CALENDAR FIX */
.DateInput_input__small {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.SingleDatePicker_picker {
  z-index: 10;
}
.DateInput__small {
  width: 100% !important;
}
.DateInput__small > input {
  text-align: center;
  height: 30px !important;
  border-radius: 5px !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #e36a53 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #e36a53 !important;
  border: 1px double #e36a53 !important;
  color: #fff;
  font-weight: bold !important;
}
.SingleDatePickerInput__withBorder {
  border-radius: 5px !important;
  border: 1px solid #dbdbdb;
}
.DateInput {
  border-radius: 5px !important;
}
/* JSON DEBUG FIX */
.__json-pretty__ {
  padding: 7px;
  border-radius: 5px;
}
/* VIRTUAL TABLE FIX */
.v-l {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.d-none {
  display: none;
}
.rce-mbox {
  padding: 6px 9px 22px 9px;
}
.rce-mbox-time {
  bottom: -9px !important;
}
.rce-container-citem {
  margin-bottom: 15px;
}
.rce-mbox.rce-mbox-right {
  overflow: hidden;
}
.rce-mbox-photo--img {
  cursor: pointer;
}
.rce-citem-avatar img {
  width: auto !important;
  height: auto !important;
}
.rce-container-citem {
  width: 350px;
}
.rce-citem {
  cursor: default !important;
}
.fc-event {
  margin-bottom: 3px;
  padding-left: 5px;
}
.scheduler_default_corner div {
  display: none;
}
.scheduler_default_timeheadercol_inner {
  border: none !important;
}
.scheduler_default_main {
  border: none !important;
}
.scheduler_default_timeheadergroup {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #894386 !important;
  background: #f8f8f9 !important;
}
.scheduler_default_corner {
  background: #f8f8f9 !important;
}
.scheduler_default_timeheadercol {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #59596a !important;
  background: #f8f8f9 !important;
}
.scheduler_default_divider_horizontal {
  background-color: transparent !important;
}
.scheduler_default_rowheader {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #59596a;
  background-color: #ffffff !important;
}
.scheduler_default_cell.scheduler_default_cell_business {
  background-color: #ffffff !important;
}
.scheduler_default_cell {
  background-color: #f9f9fe !important;
}
.scheduler_default_rowheader_inner {
  position: absolute;
  border-right: 1px solid #f9f9fe !important;
  padding: 7px;
  display: flex;
  align-items: center;
}
.scheduler_default_timeheadergroup_inner {
  border-right: none !important;
  border-bottom: 1px solid #d7e1f0b0 !important;
}
.scheduler_default_resourcedivider {
  background: #d7e1f0b0 !important;
}
.scheduler_default_divider,
.scheduler_default_splitter {
  background: #d7e1f0b0 !important;
}
.scheduler_default_matrix_horizontal_line {
  background: #d7e1f0b0 !important;
}
.scheduler_default_scrollable::-webkit-scrollbar {
  width: 2px;
}
.scheduler_default_scrollable::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}
.scheduler_default_scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #bebebe;
}
.scheduler_default_scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scheduler_default_event {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: 900;
  font-size: 14px !important;
  line-height: 16px;
  color: #465ac2 !important;
}
.scheduler_default_event_inner {
  position: absolute;
  padding: 12px !important;
  border: none !important;
}
.scheduler_default_event_bar {
  display: none;
}
.scheduler_default_event_inner {
  background: #e3eeff !important;
  border-radius: 8px !important;
  margin: 3px;
}
.rce-container-citem {
  margin: 5px 10px;
}
ul {
  list-style-type: none !important;
}
#formRow {
  margin-bottom: 0 !important;
}
.ant-descriptions .ant-descriptions-item-content {
  background: white;
  border-radius: 5px;
  padding: 2px 5px;
}
.ant-descriptions-item-label {
  background-color: rgba(236, 236, 236, 0) !important;
  width: 70px;
}
.ant-table-thead .ant-table-cell {
  background: #81b7e0 !important;
  color: #222 !important;
}
.even-row {
  background-color: white !;
}
